var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "error-page"
  }, [_c("div", {
    staticClass: "big"
  }, [_c("div", {
    staticClass: "inner"
  }, [_c("img", {
    attrs: {
      src: require("../../assets/404@2x.png")
    }
  }), _vm._v(" "), _c("p", {
    staticClass: "error-message"
  }, [_vm._v(_vm._s(_vm.$t("error404.message")))])])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };